<template>
    <standard-page :title="org.name" v-bind:definition="org.description" v-if="org">
        <template v-slot:breadcrumb>
            <span><router-link :to="{ name: 'admin-home'}">Admin</router-link></span>
            <span><router-link :to="{ name: 'organizations'}">Organizations</router-link></span>
            <span v-if="org">Organization: {{org.name | truncate(10, '......')}}</span>
        </template>

        <template v-slot:content>
            <div class="az-content-body az-content-body-profile">
                <nav class="nav az-nav-line">
                    <a :class="{active: activeTab === 0}" @click="profileClick" class="nav-link" data-toggle="tab" href="#profile">Profile</a>
                    <a :class="{active: activeTab === 1}" @click="subscriptionClick" class="nav-link" data-toggle="tab" href="#subscriptions">Subscriptions</a>
                    <a :class="{active: activeTab === 2}" @click="memberClick" class="nav-link" data-toggle="tab" href="#members">Members</a>
                </nav>
                <div class="az-tab-content">
                    <div :class="{'d-none': activeTab !== 0}" id="profile">
                        <org-profile :orgid="orgid"></org-profile>
                    </div><!-- az-profile-body -->
                    <div :class="{'d-none': activeTab !== 1}" id="subscriptions">
                        <subscriptions :orgid="orgid"></subscriptions>
                    </div><!-- az-profile-body -->
                    <div :class="{'d-none': activeTab !== 2}" id="members">
                        <members :orgid="orgid"></members>
                    </div><!-- az-profile-body -->
                </div>
            </div>
        </template>
    </standard-page>
</template>

<script>
    import Members from "./sections/Members";
    import Subscriptions from "./sections/Subscriptions";
    import OrgProfile from "./sections/OrgProfile";

    export default {
        name: "ViewOrg",
        props: ['orgid'],
        components: {OrgProfile, Subscriptions, Members},
        data() {
            return {
                activeTab: 0,
                org: null
            }
        },
        mounted() {
            this.getOrg();
        },
        methods: {
            async getOrg() {
                try {
                    let {data} = await this.$accounts.get(`/accounts/orgs/${this.orgid}`);
                    this.org = data;
                } catch (e) {

                }
            },
            profileClick() {
                this.activeTab = 0;
            },
            subscriptionClick() {
                this.activeTab = 1;
            },
            memberClick() {
                this.activeTab = 2;
            },
        }
    }
</script>

<style scoped>

</style>