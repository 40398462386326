<template>
  <div>
    <p class="mg-b-10 mg-t-20" v-html="$DEFINITIONS.admin.subscription"></p>

    <div class="row row-sm mg-b-20">
      <div class="col-lg-3 offset-lg-10">
        <router-link :to="{name: 'subscribe'}" class="btn btn-orange rounded font-weight-bold" title="Add DID">
          <i class="fas fa-plus-square mg-r-10" />Add Service
        </router-link>
      </div>
    </div>

    <data-table :api="api" :columns="columns" :server-side="true" @ready="onReady" list-key="services" table-id="subscription_table" total-key="total">
    </data-table>

    <div class="modal" id="confirmationmodal">
      <div class="modal-dialog" role="document">
        <div class="modal-content modal-content-demo">
          <div class="modal-body tx-center pd-y-20 pd-x-20">
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
            <i class="icon ion-ios-warning tx-100 tx-indigo lh-1 mg-t-20 d-inline-block"></i>
            <h4 class="tx-indigo mg-b-20">Warning!</h4>
            <p class="mg-b-20 mg-x-20">Are you sure you want to unsubscribe the <b>{{service_to_unsubscribe}}</b> service?</p>
            <button @click="unsubscribe()" class="btn rounded btn-az-primary pd-x-25 mg-t-15" data-dismiss="modal" type="button">
              Continue
            </button>
          </div>
        </div><!-- modal-content -->
      </div><!-- modal-dialog -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "@/event-bus";

export default {
  name: "Subscriptions",
  computed: {
    ...mapGetters(["currentUser"])
  },
  props: ['orgid'],
  data() {
    return {
      api: `${this.$accounts.defaults.baseURL}/accounts/orgs/${this.orgid}/subscriptions`,
      columns: [
        { type: 'name' },
        { type: 'description' },
        {
          title: 'Action',
          orderable: false,
          data: 'status',
          className: 'text-center w-150',
          render: (data, type, row) => {
            return `<span  data-target="#confirmationmodal" data-toggle="modal" class="text-danger bg-white fa-border text-decoration-none unsubscribe" style="cursor: pointer" title="Unsubscribe">
                                        <i class="fas fa-minus-circle"></i> Unsubscribe
                                    </span>`;
          },
        },
      ],
      table: null,
      service_to_unsubscribe: null,
      service_id_to_unsubscribe: null
    };
  },
  mounted() {

  },
  methods: {
    onReady(table) {
      this.table = table;
      let self = this;

      $('#subscription_table tbody').on('click', '.unsubscribe', function () {
        let row = table.row($(this).parents('tr')).data();
        self.setServiceToUnsubscribe(row);
        table.ajax.reload();
      });
    },
    setServiceToUnsubscribe(service) {
      this.service_id_to_unsubscribe = service.service;
      this.service_to_unsubscribe = service.name;
    },
    async unsubscribe() {
      try {
        EventBus.$emit('openLoader');
        await this.$accounts.post(`/accounts/services/${this.service_id_to_unsubscribe}/unsubscribe`, {
          org: Number(this.orgid)
        });
        this.$toastr.s(`${this.service_to_unsubscribe} service Successfully unsubscribed!`, 'Success');
        this.service_id_to_unsubscribe = null;
        this.service_to_unsubscribe = null;
        this.table.ajax.reload();
      } catch (e) {
        this.$toastr.e(`${this.service_to_unsubscribe} service unsubscribe failed!`, 'Error');
      } finally {
        EventBus.$emit('closeLoader');
      }
    }
  }
}
</script>

<style scoped>
</style>