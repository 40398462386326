<template>
  <div>
    <p class="mg-b-10 mg-t-20" v-html="$DEFINITIONS.admin.members"></p>
    <data-table :api="api" :columns="columns" :server-side="true" @ready="onReady" list-key="users" table-id="org_member_table" total-key="total">
    </data-table>
  </div>
</template>

<script>
import EventBus from "@/event-bus";

export default {
  name: "Members",
  props: ['orgid'],
  data() {
    return {
      api: `${this.$accounts.defaults.baseURL}/accounts/orgs/${this.orgid}/members`,
      columns: [
        { type: 'email' },
        { type: 'first_name' },
        { type: 'last_name' },
        { type: 'toggle', data: 'status' },
          {
              title: 'Console Access',
              data: 'mobile_only',
              orderable: false,
              render: (data, type, row) => {
                  switch (data) {
                      case true:
                          return `<div class="mobile_only_disable az-toggle"><span></span></div>`;
                      case false:
                          return `<div class="mobile_only_enable az-toggle on"><span></span></div>`;
                  }
              },
          },
      ],
      table: null,

    };
  },
  methods: {
    onReady(table) {
      this.table = table;
      let self = this;

      $(async function () {
        $('#org_member_table tbody').on('click', '.enable', function (e) {
          let row = table.row($(this).parents('tr')).data();
          self.enableUser(row.user);
          table.ajax.reload();
        });

        $('#org_member_table tbody').on('click', '.disable', function (e) {
          let row = table.row($(this).parents('tr')).data();
          self.disableUser(row.user);
          table.ajax.reload();
        });

          $('#org_member_table tbody').on('click', '.mobile_only_enable', function (e) {
              let row = table.row($(this).parents('tr')).data();
              self.consoleAccess(row.user, true);
              table.ajax.reload();
          });

          $('#org_member_table tbody').on('click', '.mobile_only_disable', function (e) {
              let row = table.row($(this).parents('tr')).data();
              self.consoleAccess(row.user, false);
              table.ajax.reload();
          });
      });
    },
    view(user) {
      this.$router.push({ name: 'userroles', params: { userid: user.user } });
    },
    async enableUser(userid) {
      try {
        EventBus.$emit('openLoader');
        await this.$accounts.post(`accounts/users/${userid}/enable`);
        this.table.ajax.reload();
      } catch (e) {

      } finally {
        EventBus.$emit('closeLoader');
      }
    },
      async consoleAccess(userid, mobile_only) {
          try {
              EventBus.$emit('openLoader');
              await this.$accounts.post(`accounts/users/${userid}/console_access`, {
                  mobile_only: mobile_only
              });
              this.table.ajax.reload();
          } catch (e) {

          } finally {
              EventBus.$emit('closeLoader');
          }
      },
    async disableUser(userid) {
      try {
        EventBus.$emit('openLoader');
        await this.$accounts.post(`accounts/users/${userid}/disable`);
        this.table.ajax.reload();
      } catch (e) {

      } finally {
        EventBus.$emit('closeLoader');
      }
    },
  }
}
</script>

<style scoped>
</style>