<template>
    <div class="az-profile-view-chart">
        <div class="az-profile-view-info col-md-12">
            <div class="az-contact-info-body ps" style="margin-left: -36px" v-if="org">
                <div class="media-list">
                    <div class="media">
                        <div class="media-icon"><i class="fas fa-mobile-alt"></i></div>
                        <div class="media-body">
                            <div>
                                <label>Name</label>
                                <span class="tx-medium">{{org.name}}</span>
                            </div>
                            <div class="media-icon"><i class="fas fa-globe"></i></div>
                            <div>
                                <label>Website</label>
                                <span class="tx-medium">{{org.web}}</span>
                            </div>
                        </div><!-- media-body -->
                    </div><!-- media -->
                    <div class="media">
                        <div class="media-icon align-self-start"><i class="far fa-envelope"></i></div>
                        <div class="media-body">
                            <div>
                                <label>Email Account</label>
                                <span class="tx-medium">{{org.email}}</span>
                            </div>
                            <div class="media-icon align-self-start"><i class="fas fa-mobile-alt"></i></div>
                            <div>
                                <label>Contact No.</label>
                                <span class="tx-medium">{{org.phone}}</span>
                                <span class="tx-medium" v-if="!org.phone || org.phone == ''">N/A</span>
                            </div>
                        </div><!-- media-body -->
                    </div><!-- media -->
                    <div class="media">
                        <div class="media-icon"><i class="far fa-map"></i></div>
                        <div class="media-body">
                            <div>
                                <label>Current Address</label>
                                <span class="tx-medium">{{org.address.line1}},
                                {{org.address.line2}},
                                {{org.address.country}},
                                {{org.address.state}},
                                {{org.address.city}},
                                {{org.address.zip}}</span>
                            </div>
                        </div><!-- media-body -->
                    </div><!-- media -->
                </div><!-- media-list -->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OrgProfile",
        props: ['orgid'],
        data() {
            return {
                org: null
            }
        },
        mounted() {
            this.getOrg();
        },
        methods: {
            async getOrg() {
                try {
                    let {data} = await this.$accounts.get(`/accounts/orgs/${this.orgid}`);
                    this.org = data;
                } catch (e) {

                }
            },
        }
    }
</script>

<style scoped>

</style>